import { useMantineTheme } from "@mantine/core";
import { Marker as MapboxMarker } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import Location from "../../domain/location";
import { RootState } from "../../store";
import {
  selectLocation,
  setHoveredLocation,
  unsetHoveredLocation,
} from "../../store/slices/locationSlice";

interface Props {
  location: Location;
  hoverable?: boolean;
}

const Marker = ({ location, hoverable }: Props) => {
  const dispatch = useDispatch();

  const hoveredLocation = useSelector(
    (state: RootState) => state.location.hoveredLocation
  );

  const onMarkerSelected = () => {
    dispatch(selectLocation(location));
  };

  const onMouseEnter = () => {
    dispatch(setHoveredLocation(location));
  };

  const onMouseLeave = () => {
    dispatch(unsetHoveredLocation());
  };

  const isHovered = (): boolean => {
    return hoveredLocation === location;
  };

  const { primaryColor } = useMantineTheme();

  return (
    <MapboxMarker
      longitude={location.longitude}
      latitude={location.latitude}
      anchor="center"
      onClick={onMarkerSelected}
      style={{ zIndex: hoverable && isHovered() ? 100 : "auto" }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 19 19"
        height="19"
        width="19"
        onMouseEnter={hoverable ? onMouseEnter : undefined}
        onMouseLeave={hoverable ? onMouseLeave : undefined}
        style={{ cursor: "pointer" }}
      >
        <title>{location.name}</title>
        <rect fill="none" x="0" y="0" width="19" height="19"></rect>
        <rect
          x="0"
          y="0"
          width="19"
          height="19"
          rx="9.5"
          ry="9.5"
          fill="#ffffff"
        ></rect>
        <path
          fill={hoverable && isHovered() ? "green" : primaryColor}
          transform="translate(2 2)"
          d="M14,7.5c0,3.5899-2.9101,6.5-6.5,6.5S1,11.0899,1,7.5S3.9101,1,7.5,1S14,3.9101,14,7.5z"
        ></path>
      </svg>
    </MapboxMarker>
  );
};

export default Marker;
