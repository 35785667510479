import { Route, Routes } from "react-router-dom";

import Layout from "../../layout";
import About from "../about/About";
import Home from "../home/Home";
import LocationPage from "../location-page/LocationPage";
import NotFound from "../not-found/NotFound";
import PrivacyPolicy from "../privacy-policy/PrivacyPolicy";


const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/" element={<Layout />}>
        <Route path="about" element={<About />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="locations/:id" element={<LocationPage />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export default App;
