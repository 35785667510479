import { Divider, useMantineTheme } from "@mantine/core";

import FilterBar from "../../components/filter-bar/FilterBar";
import LocationList from "../../components/location-list/LocationList";
import Map from "../../components/map/Map";
import SEO from "../../components/seo/SEO";
import Header from "../../components/header/Header";

import "./Home.css";
import CookieBanner from "../../components/cookie-banner/CookieBanner";

const Home = () => {
  const { colors } = useMantineTheme();
  return (
    <div className="home">
      <Header />
      <SEO
        title="Tickmark Homepage"
        description="Mapping out climbing hostels and campgrounds around the world"
        type="website"
        name="Tickmark"
      />
      <div className="home__content">
        <div className="home__filter-section">
          <div className="home__filter-bar-section">
            <FilterBar />
          </div>
          <div className="home__map">
            <Map />
          </div>
        </div>
        <Divider size="xs" orientation="vertical" color={colors.gray[3]} />
        <div className="home__result-section">
          <div className="cards">
            <LocationList />
          </div>
        </div>
      </div>
      <CookieBanner />
    </div>
  );
};

export default Home;
