import { Home, World } from "tabler-icons-react";

import Location from "../../domain/location";

import "./LinkPanel.css";

interface Props {
  location: Location;
  className?: String;
}

const LinkPanel = ({ location, className }: Props) => {
  return (
    <div className={`link-panel ${className}`}>
      <div className="link-panel__list">
        <a
          href={location.website}
          className="link-panel__item"
          target="_blank"
          rel="noreferrer"
        >
          <World />
          <p style={{ textDecoration: "none" }}>Visit homepage</p>
        </a>
        {location.bookingPlatform ? (
          <a href={location.bookingPlatform} target="_blank" rel="noreferrer">
            <Home />
            <p>Check availability</p>
          </a>
        ) : null}
      </div>
    </div>
  );
};

export default LinkPanel;
