import { defaultTo } from "lodash";

const CLOUDINARY_CLOUD_NAME = "dezbcqvaz";
const CLOUDINARY_FOLDER = "tickmark";

const COOKIE_BANNER_DEBUG = defaultTo(
  process.env.REACT_APP_COOKIE_BANNER_DEBUG?.toLocaleLowerCase() === "true",
  false
);
export { CLOUDINARY_CLOUD_NAME, CLOUDINARY_FOLDER, COOKIE_BANNER_DEBUG };
