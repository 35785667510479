import Month, { between } from "../domain/month";
import { FilterState } from "../store/slices/filterSlice";
import Location from "../domain/location";

const matchActivity = (
  { activities }: Location,
  { activity }: FilterState
): boolean => {
  if (!activity) {
    return true;
  }
  return activities.indexOf(activity) !== -1;
};

const matchMapBounds = (
  { longitude, latitude }: Location,
  { mapBounds }: FilterState
): boolean => {
  const [[minLng, minLat], [maxLng, maxLat]] = mapBounds;
  return (
    minLng < longitude &&
    longitude < maxLng &&
    minLat < latitude &&
    latitude < maxLat
  );
};

const matchSeason = (
  { season }: Location,
  { startMonth, endMonth }: FilterState
): boolean => {
  if (startMonth === null || endMonth === null) {
    return true;
  }
  const selectedMonths: Month[] = between(startMonth, endMonth);
  return selectedMonths.reduce(
    (prev, month) => prev && season.indexOf(month) !== -1,
    true
  );
};

export {matchActivity, matchMapBounds, matchSeason}