import { Popover, Text, ThemeIcon } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

interface Props {
  label: string;
  color: string;
}

const ActivityPin = ({label, color}: Props) => {
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Popover
      position="bottom"
      withArrow
      shadow="md"
      opened={opened}
    >
      <Popover.Target>
        <ThemeIcon
          size="xs"
          radius="xl"
          color={color}
          onMouseEnter={open}
          onMouseLeave={close}
        >
          <Text size="xs">{label.at(0)}</Text>
        </ThemeIcon>
      </Popover.Target>
      <Popover.Dropdown sx={{ pointerEvents: "none" }} >
        <Text size="sm">{label}</Text>
      </Popover.Dropdown>
    </Popover>
  );
};

export default ActivityPin;
