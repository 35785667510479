import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import Location from "../../domain/location";

import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { Margin } from "recharts/types/util/types";
import Month from "../../domain/month";
import { useMantineTheme } from "@mantine/core";

interface Props {
  location: Location;
  onMonthHovered: (arg0: Month) => void;
  syncId?: string;
  margin?: Margin;
  fontsize?: number;
  className?: string;
}

const TemperatureChart = ({
  location,
  onMonthHovered,
  syncId,
  margin,
  className,
}: Props) => {
  const theme = useMantineTheme();

  const formatTmpTicks = (tmp: number) => {
    return tmp.toString() + "°C";
  };

  const onMouseMove = (e: CategoricalChartState) => {
    if (e.activeLabel) {
      onMonthHovered(e.activeLabel as Month);
    }
  };

  return (
    <ResponsiveContainer width="100%" height={160} className={className}>
      <ComposedChart
        data={location.climate}
        syncId={syncId}
        margin={margin}
        onMouseMove={onMouseMove}
      >
        <Line type="monotone" dataKey="avgLow" stroke={theme.colors.blue[9]} strokeWidth={2} dot={false} />
        <Line type="monotone" dataKey="avgHigh" stroke={theme.colors.red[9]} strokeWidth={2} dot={false} />
        <Bar dataKey="nonexist" fill="#82ca9d" />
        <Bar dataKey="nonexist" fill="#82ca9d" />
        <CartesianGrid stroke="#ccc" vertical={true} strokeDasharray="3 3" />
        <YAxis
          tick={{ fontSize: 12 }}
          tickFormatter={formatTmpTicks}
          interval={0}
        />
        <YAxis yAxisId="ab" interval={0} orientation="right" tick={false} />
        <Tooltip content={() => null} />
        <XAxis
          xAxisId="a"
          orientation="top"
          interval={0}
          tickLine={false}
          tick={false}
        />
        <XAxis
          dataKey="month"
          tickFormatter={() => ""}
          dy={5}
          interval={0}
          tickLine={false}
          orientation="bottom"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default TemperatureChart;
