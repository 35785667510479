import { configureStore } from "@reduxjs/toolkit";
import { locationsApi } from "./slices/locationsApi";

import filterSlice from "./slices/filterSlice";
import locationSlice from "./slices/locationSlice";

export const store = configureStore({
  reducer: {
    location: locationSlice.reducer,
    filter: filterSlice.reducer,
    [locationsApi.reducerPath]: locationsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(locationsApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
