import { Select } from "@mantine/core";
import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
} from "@reduxjs/toolkit";
import { IconChevronDown, IconX } from "@tabler/icons";
import { useDispatch, useSelector } from "react-redux";

import {
  setEndMonth,
  setStartMonth,
  unsetEndMonth,
  unsetStartMonth,
} from "../../store/slices/filterSlice";

import { useCallback, useMemo } from "react";
import Month, { months } from "../../domain/month";
import { RootState } from "../../store";
import "./MonthFilter.css";

type Props = {
  placeHolder: string;
  monthSetter: ActionCreatorWithPayload<Month, string>;
  monthUnsetter: ActionCreatorWithoutPayload<string>;
  monthSelector: (arg0: RootState) => Month | null;
};

const monthOptions = months.map((month) => {
  return { label: month.toString(), value: month as Month };
});

const GenericMonthFilter = ({
  placeHolder,
  monthSetter,
  monthUnsetter,
  monthSelector,
}: Props) => {
  const dispatch = useDispatch();

  const onMonthSelected = useCallback(
    (value: Month) => {
      if (value) {
        dispatch(monthSetter(value));
        return;
      }

      dispatch(monthUnsetter());
    },
    [dispatch, monthSetter, monthUnsetter]
  );

  const onClearButtonClicked = useCallback(() => {
    dispatch(monthUnsetter());
  }, [dispatch, monthUnsetter]);

  const selectedMonth = useSelector(monthSelector);

  const rightIcon = useMemo(() => {
    return selectedMonth ? (
      <IconX
        size={14}
        onClick={onClearButtonClicked}
        style={{ cursor: "pointer" }}
      />
    ) : (
      <IconChevronDown size={14} style={{ cursor: "text" }} />
    );
  }, [selectedMonth, onClearButtonClicked]);

  return (
    <Select
      value={selectedMonth}
      placeholder={placeHolder}
      data={monthOptions}
      onChange={onMonthSelected}
      searchable
      clearable
      rightSection={rightIcon}
      styles={selectedMonth ? {} : { rightSection: { pointerEvents: "none" } }}
    />
  );
};

const MonthFilter = () => {
  const fromMonthSelector = useCallback((state: RootState) => {
    return state.filter.startMonth;
  }, []);
  const toMonthSelector = useCallback((state: RootState) => {
    return state.filter.endMonth;
  }, []);

  return (
    <div className="monthFilter">
      <div className="from-filter">
        <GenericMonthFilter
          placeHolder="From"
          monthSetter={setStartMonth}
          monthUnsetter={unsetStartMonth}
          monthSelector={fromMonthSelector}
        />
      </div>
      <div className="to-filter">
        <GenericMonthFilter
          placeHolder="To"
          monthSetter={setEndMonth}
          monthUnsetter={unsetEndMonth}
          monthSelector={toMonthSelector}
        />
      </div>
    </div>
  );
};

export default MonthFilter;
