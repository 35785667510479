import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { Container, Divider } from "@mantine/core";
import Footer from "../../components/footer/Footer";
import LocationDetails from "../../components/location-details/LocationDetails";
import SEO from "../../components/seo/SEO";
import { useGetLocationsQuery } from "../../store/slices/locationsApi";

const LocationPage = () => {
  let { id } = useParams();
  let { data: locations } = useGetLocationsQuery();

  let location = useMemo(() => {
    return locations?.find((location) => location.id.toString() === id);
  }, [id, locations]);

  return (
    <div>
      <SEO
        title="Tickmark Location Detail"
        description=" Detailed information about the specific climbing hostel or campground"
        type="website"
        name="Tickmark"
      />
      <Container size="md" px={10}>
        {location ? <LocationDetails location={location} /> : null}
        <Divider m="2rem" />
        <Footer />
      </Container>
    </div>
  );
};

export default LocationPage;
