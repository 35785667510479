import { MantineProvider } from "@mantine/core";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { HelmetProvider } from "react-helmet-async";
import App from "./pages/app/App";
import { store } from "./store";

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <BrowserRouter>
          <MantineProvider
            withCSSVariables
            withGlobalStyles
            withNormalizeCSS
            theme={{
              colorScheme: "light",
              defaultRadius: "md",
              white: "#ffffff",
              black: "#373737",

              primaryColor: "red",
              fontFamily: "Circular, sans-serif",

              components: {
                Container: {
                  defaultProps: {
                    sizes: {
                      xs: 540,
                      sm: 720,
                      md: 960,
                      lg: 1600,
                      xl: 1920,
                    },
                  },
                },
              },
            }}
          >
            <App />
          </MantineProvider>
        </BrowserRouter>
      </Provider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
