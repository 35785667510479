import { useCallback, useMemo, useRef } from "react";
import {
  MapProvider,
  MapRef,
  default as Mapbox,
  ViewStateChangeEvent
} from "react-map-gl";

import { useDispatch, useSelector } from "react-redux";
import Location from "../../domain/location";
import useFilteredLocationsBySeasonAndActivity from "../../hooks/useFilteredLocationsBySeasonAndActivity";
import { RootState } from "../../store";
import {
  LngLatBounds,
  mapBounds,
  setMapBounds,
} from "../../store/slices/filterSlice";
import LocationPopup from "../location-popup/LocationPopup";
import Marker from "../marker/Marker";

import "./Map.css";

const renderLocationMarkers = (locations: Location[]) => {
  if (!locations) {
    return null;
  }
  return locations.map((location) => (
    <Marker location={location} key={location.name} hoverable />
  ));
};

const Map = () => {
  const mapRef = useRef<MapRef>(null);

  const onMapLoad = useCallback(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      //turn off option to rotate map with SHIFT+Cursor
      map.keyboard.disableRotation();
      map.touchZoomRotate.enable();
      map.touchZoomRotate.disableRotation();
    }
  }, [mapRef]);

  let locations = useFilteredLocationsBySeasonAndActivity();
  let markers = useMemo(() => renderLocationMarkers(locations), [locations]);
  const dispatch = useDispatch();
  let onMapboxMoved = useCallback(
    (e: ViewStateChangeEvent) => {
      const mapBoxBounds = e.target.getBounds();
      const ne = mapBoxBounds.getNorthEast();
      const sw = mapBoxBounds.getSouthWest();
      const mapBounds: LngLatBounds = [
        [sw.lng, sw.lat],
        [ne.lng, ne.lat],
      ];
      dispatch(setMapBounds(mapBounds));
    },
    [dispatch]
  );

  const selectedLocation = useSelector(
    (state: RootState) => state.location.selectedLocation
  );

  let popup = useMemo(() => {
    if (!selectedLocation) {
      return null;
    }
    return <LocationPopup location={selectedLocation} />;
  }, [selectedLocation]);

  return (
    <div className="map-container">
      <MapProvider>
        <Mapbox
          ref={mapRef}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          // TODO remove https://visgl.github.io/react-map-gl/docs/get-started/tips-and-tricks
          mapboxAccessToken="pk.eyJ1IjoiY2hpbm5heHMiLCJhIjoiY2w3Ym9mYzdoMGRpdzNyc2QzMGhncm94ZCJ9.uK35w7zfDV691AGr50gbTA"
          onMoveEnd={onMapboxMoved}
          renderWorldCopies={false}
          maxBounds={mapBounds}
          dragRotate={false}
          touchZoomRotate={false}
          onLoad={onMapLoad}
        >
          {popup}
          {markers}
        </Mapbox>
      </MapProvider>
    </div>
  );
};

export default Map;
