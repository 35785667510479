import { Link } from "react-router-dom";

import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <Link className="footer__link"to="/privacy">Privacy Policy</Link>
    </div>
  );
};

export default Footer;
