import { Container, Title } from "@mantine/core";

const NotFound = () => {
  return (
    <div>
      <Container size="md" px={10}>
        <Title order={2} mt="2rem">
          Page could not be found
        </Title>
      </Container>
    </div>
  );
};

export default NotFound;
