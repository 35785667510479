import { Adsense } from "@ctrl/react-adsense";

const AdBanner = () => {
  return (
    <Adsense
      style={{ display: "block", minHeight: "100px" }}
      client="ca-pub-6228167008468327"
      slot="4536411730"
      format="auto"
      responsive="true"
    />
  );
};

export default AdBanner;
