import { Button, Modal, TextInput, Textarea, Title, Text } from "@mantine/core";
import { useForm } from "@mantine/form";

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormData {
  firstName?: string;
  lastName?: string;
  email: string;
  message: string;
}

const ContactModal = ({ open, setOpen }: Props) => {
  const form = useForm<FormData>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      message: "",
    },
    validate: {
      email: (value: string) => {
        if (value === "") {
          return "Please enter your email address";
        }
        if (!/^\S+@\S+$/.test(value)) {
          return "Invalid email";
        }
        return null;
      },
      message: (value) => {
        if (value === "") {
          return "Please enter your message";
        }
        return null;
      },
    },
  });

  const postMessage = (formdata: FormData) => {
    fetch(
      "https://public.herotofu.com/v1/f85618a0-82ef-11ed-b38f-a1ed22f366b1",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata),
      }
    );
  };

  return (
    <Modal
      closeOnClickOutside={false}
      opened={open}
      onClose={() => setOpen(false)}
    >
      <Title order={3}>Contact us!</Title>
      <Text mb={"0.5rem"}>Is a place missing? Want to give feedback?</Text>
      <form onSubmit={form.onSubmit((formData) => postMessage(formData))}>
        <TextInput
          mb={"0.25rem"}
          label="First name"
          {...form.getInputProps("firstName")}
        />
        <TextInput
          mb={"0.25rem"}
          label="Last name"
          {...form.getInputProps("lastName")}
        />
        <TextInput
          mb={"0.25rem"}
          label="Your email"
          withAsterisk
          {...form.getInputProps("email")}
        />
        <Textarea
          mb={"1rem"}
          label="Your message"
          withAsterisk
          {...form.getInputProps("message")}
        />
        <Button type="submit">Submit</Button>
      </form>
    </Modal>
  );
};

export default ContactModal;
