import Banner from "../banner/Banner";
import MenuBar from "../menu-bar/MenuBar";

import "./Header.css";

const Header = () => {

  return (
    <div className="header">
          <Banner />
          <div className="header__menu-bar">
            <MenuBar />
          </div>
    </div>
  );
};

export default Header;
