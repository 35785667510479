import { Divider, Text, Title } from "@mantine/core";

import Location from "../../domain/location";
import ActivityBadgeRow from "../activity-badge-row/ActivityBadgeRow";
import ClimateChart from "../climate-chart/ClimateChart";
import DynamicImage from "../dynamic-image/DynamicImage";
import FixedMap from "../fixed-map/FixedMap";
import LinkPanel from "../link-panel/LinkPanel";

import AdBanner from "../ad-banner/AdBanner";
import "./LocationDetails.css";

interface Props {
  location: Location;
}

const LocationDetails = ({ location }: Props) => {
  return (
<>
      <DynamicImage
        className="location-details__image"
        id={location.id}
        fetchingHeight={350}
        fetchingWidth={500}
        alt={location.name}
      />
      <div className="location-details__title">
        <Title order={2}>{location.name}</Title>
        <ActivityBadgeRow activities={location.activities} />
      </div>
      <Text fz="sm" c="dimmed">
        {location.area}, {location.country}
      </Text>
      <Divider className="location-details__divider" />
      <div className="location-details__content">
        <div className="location-details__info">
          <Title order={3} mb="0.5rem">
            Climate
          </Title>
          <ClimateChart location={location} />
          <Divider className="location-details__divider" />
          <Title order={3} mb="0.5rem">
            Location
          </Title>
          <FixedMap
            className="location-details__fixed-map"
            location={location}
          />
        </div>
        <div className="location-detail__panels">
          <LinkPanel
            location={location}
            className="location-detail__link-panel"
          />
          <AdBanner />
        </div>
      </div>
      </>
  );
};

export default LocationDetails;
