import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import Location from "./../../domain/location";

interface LocationState {
  selectedLocation?: Location;
  hoveredLocation?: Location;
}

const initialState: LocationState = {
  selectedLocation: undefined,
  hoveredLocation: undefined,
};

const locationSlice = createSlice({
  name: "location",
  initialState: initialState,
  reducers: {
    selectLocation: (state, action: PayloadAction<Location>) => {
      state.selectedLocation = action.payload;
    },
    unselectLocation: (state) => {
      state.selectedLocation = undefined;
    },
    setHoveredLocation: (state, action: PayloadAction<Location>) => {
      state.hoveredLocation = action.payload
    },
    unsetHoveredLocation: (state) => {
      state.hoveredLocation = undefined;
    }
  },
});

export const {
  selectLocation,
  unselectLocation,
  setHoveredLocation,
  unsetHoveredLocation

} = locationSlice.actions;
export default locationSlice;
