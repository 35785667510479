import { Burger, Button, Menu, Text } from "@mantine/core";
import { Dispatch, SetStateAction, useState } from "react";

import ContactModal from "../contact-modal/ContactModal";

import "./MenuBar.css";
import { Link } from "react-router-dom";

interface MenuBarItemProps {
  setContactModalOpen: Dispatch<SetStateAction<boolean>>;
  className?: string;
}

const MenuBarDesktop = ({
  setContactModalOpen,
  className,
}: MenuBarItemProps) => {
  return (
    <div className={className}>
      <Button>
        <Link to="about">
          <Text color="white">About</Text>
        </Link>
      </Button>
      <Button onClick={() => setContactModalOpen(true)}>Contact</Button>
    </div>
  );
};

const MenuBarMobile = ({
  setContactModalOpen,
  className,
}: MenuBarItemProps) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className={className}>
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <div onClick={() => setOpen}>
            <Burger opened={open} color="white" />
          </div>
        </Menu.Target>

        <Menu.Dropdown className="menu-bar__dropdown">
          <Menu.Item className="menu-bar__item">
            <Link to="about">
              <Text color="white">About</Text>
            </Link>
          </Menu.Item>
          <Menu.Item
            className="menu-bar__item"
            onClick={() => setContactModalOpen(true)}
          >
            Contact
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

const MenuBar = () => {
  const [contactModalOpen, setContactModalOpen] = useState<boolean>(false);

  return (
    <>
      <MenuBarDesktop
        className="menu-bar--desktop"
        setContactModalOpen={setContactModalOpen}
      />
      <MenuBarMobile
        className="menu-bar--mobile"
        setContactModalOpen={setContactModalOpen}
      />
      <ContactModal open={contactModalOpen} setOpen={setContactModalOpen} />
    </>
  );
};

export default MenuBar;
