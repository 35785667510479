import { useMemo } from "react";
import { useSelector } from "react-redux";

import Location from "../domain/location";
import { RootState } from "../store";
import { FilterState } from "../store/slices/filterSlice";
import { useGetLocationsQuery } from "../store/slices/locationsApi";
import { matchActivity, matchMapBounds, matchSeason } from "./criteriaMatcher";


const filterLocation = (
  locations: Location[] | undefined,
  filterState: FilterState
): Location[] => {
  if (!locations) {
    return [];
  }
  return locations
    .filter((location) => matchActivity(location, filterState))
    .filter((location) => matchMapBounds(location, filterState))
    .filter((location) => matchSeason(location, filterState));
};

const useFilteredLocations = () => {
  const { data: locations } = useGetLocationsQuery();
  const filterState = useSelector((state: RootState) => state.filter);
  return useMemo(
    () => filterLocation(locations, filterState),
    [locations, filterState]
  );
};

export default useFilteredLocations;
