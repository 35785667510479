import { Container, Divider, Text, Title } from "@mantine/core";

import Footer from "../../components/footer/Footer";
import "./About.css";

const About = () => {
  return (
      <Container size="md" mt={30} px={10}>
        <Title order={2} mt="4rem" mb="0.5rem">
          Vision & Motivation
        </Title>
        <Text>
          The vision of <strong>Tickmark</strong> is to provide information
          about climber hostels and campgrounds around the world for backpacking
          rock climbers.
          <br />
          <br />
          <strong>Tickmark</strong> is founded by adventure-seeking rock
          climbers, who have travelled throughout different parts of the world.
          Some of us have come to realize that, out there, there exists a
          vibrant traveling rock climber community. This community strongly
          centers around hostels, cottages and campgrounds. Whether journeying
          alone, in groups, or in vans, they have always been welcomed with open
          arms in such places. Soon, they find themselves among the most
          passionate like minded people pushing hard every day as long as skin
          permits and spending time in the evening on beers and stories.
          Unfortunately, information about these special places has been passed
          down through word of mouth, leaving many climbers in the dark
          regarding similar spots. With this in mind,
          <strong>Tickmark</strong> was created to map out and share these
          unique places with fellow travelers and inspire the community to
          create new ones. If you know of a place that should be here, let us
          know!
        </Text>
        <Divider m="2rem" />
        <Title order={2} mb="0.5rem">
          Disclaimer
        </Title>
        <Text>
          While <strong>Tickmark</strong> strives to provide accurate,
          up-to-date data shared by volunteers, we cannot guarantee the
          completeness or correctness of the information. By visiting this site,
          we kindly ask that you understand and accept that Tickmark holds no
          responsibility for the data provided.
        </Text>
        <Divider m="2rem" />
        <Title order={2} mb="0.5rem">
          Imprint
        </Title>
        <Text>
          tickmark.org
          <br />
          tickmark.climbing@gmail.com
          <br />
          Switzerland
        </Text>
        <Divider m="2rem" />
        <Footer />
      </Container>
  );
};

export default About;
