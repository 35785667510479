import { Image, Text, Title } from "@mantine/core";
import { Popup as MapboxPopup } from "react-map-gl";
import { useDispatch } from "react-redux";

import Location, { getInfoPageURI } from "../../domain/location";
import { unselectLocation } from "../../store/slices/locationSlice";

import "./LocationPopup.css";
import { Link } from "react-router-dom";

interface Props {
  location: Location;
  className?: string;
}

const LocationPopup = ({ location }: Props) => {
  const dispatch = useDispatch();

  const onCloseClicked = () => {
    dispatch(unselectLocation());
  };

  return (
    <MapboxPopup
      longitude={location.longitude}
      latitude={location.latitude}
      anchor="bottom"
      onClose={onCloseClicked}
      focusAfterOpen={false}
      className="location-popup"
    >
      <div className="location-popup__content">
        <Link to={getInfoPageURI(location)} style={{ textDecoration: "none" }}>
          <div className="image">
            <Image
              radius="sm"
              src={location.imgSrc}
              height={100}
              alt="Thumbnail"
            />
          </div>
          <Title order={6} className="title">
            {location.name}
          </Title>
          <Text fz="xs" c="dimmed">
            {location.area}
          </Text>
        </Link>
      </div>
    </MapboxPopup>
  );
};

export default LocationPopup;
