import { useMantineTheme } from "@mantine/core";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import {
  CategoricalChartState
} from "recharts/types/chart/generateCategoricalChart";
import { Margin } from "recharts/types/util/types";
import Location from "../../domain/location";
import Month from "../../domain/month";

interface Props {
  location: Location;
  onMonthHovered: (arg0: Month) => void;
  syncId?: string;
  margin?: Margin;
  className?: string;
}

const PrecitipationChart = ({
  location,
  onMonthHovered,
  syncId,
  margin,
  className,
}: Props) => {
  const theme = useMantineTheme();

  const formatMonthTicks = (month: Month) => {
    return month[0];
  };

  const formatPrcSumTicks = (prcSum: number) => {
    return prcSum.toString() + "mm";
  };

  const formatPrcDaysTicks = (prcDays: number) => {
    return prcDays.toString() + "d";
  };

  const onMouseMove = (e: CategoricalChartState) => {
    if (e.activeLabel) {
      onMonthHovered(e.activeLabel as Month);
    }
  };

  return (
    <ResponsiveContainer width="100%" height={160} className={className}>
      <BarChart
        data={location.climate}
        syncId={syncId}
        barSize={7}
        margin={margin}
        onMouseMove={onMouseMove}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          xAxisId="a"
          orientation="top"
          interval={0}
          tickLine={false}
          tick={false}
        />
        <XAxis
          dataKey="month"
          tick={{ fontSize: 12 }}
          tickFormatter={formatMonthTicks}
          dy={5}
          interval={0}
        />
        <YAxis
          tick={{ fontSize: 12 }}
          tickFormatter={formatPrcSumTicks}
          yAxisId="prcSum"
          interval={0}
          orientation="left"
        />
        <YAxis
          tick={{ fontSize: 12 }}
          tickFormatter={formatPrcDaysTicks}
          yAxisId="prcDays"
          interval={0}
          orientation="right"
        />
        <Tooltip content={() => null} />
        <Bar yAxisId="prcSum" dataKey="avgPrcSum" fill={theme.colors.yellow[9]} />
        <Bar yAxisId="prcDays" dataKey="avgPrcDays" fill={theme.colors.green[9]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PrecitipationChart;
