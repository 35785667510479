import _ from "lodash";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
] as const;

type Month = typeof months[number];

const buildMonthToIdxMap = () => {
  const map = new Map();
  months.forEach((m, i) => {
    map.set(m, i);
  });
  return map;
};

const monthIdx = buildMonthToIdxMap();

const between = (start: Month, end: Month): Month[] => {
  const startIdx: number = monthIdx.get(start);
  const endIdx: number = monthIdx.get(end);
  if (startIdx <= endIdx) {
    return _.range(startIdx, endIdx + 1).map((m) => months[m]);
  }
  return _.range(startIdx, months.length)
    .concat(_.range(0, endIdx + 1))
    .map((m) => months[m] as Month);
};

export default Month;
export { between, months };
