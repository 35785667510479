import { useMantineTheme } from "@mantine/core";
import CookieConsent from "react-cookie-consent";
import { COOKIE_BANNER_DEBUG } from "../../constants";

const CookieBanner = () => {
  const theme = useMantineTheme();
  return (
    <CookieConsent
      location="bottom"
      buttonText="I understand"
      cookieName="cookieConsent"
      style={{ background: theme.black }}
      buttonStyle={{ color: "#4e503b", fontSize: "15px", padding:"0.25rem"}}
      expires={150}
      debug={COOKIE_BANNER_DEBUG}
    >
      This website uses cookies to enhance the user experience.
    </CookieConsent>
  );
};

export default CookieBanner;
