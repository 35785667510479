import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import Activity from "../../domain/activity";
import Month from "../../domain/month";

type LngLatBounds = [[number, number], [number, number]];
// TODO move
const mapBounds: LngLatBounds = [
  [-180, -80], // Southwest coordinates
  [180, 80], // Northeast coordinates
];

interface FilterState {
  mapBounds: LngLatBounds;
  startMonth: Month | null;
  endMonth: Month | null;
  activity?: Activity;
}

const initialState: FilterState = {
  mapBounds: mapBounds,
  startMonth: null,
  endMonth: null,
  activity: undefined,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setMapBounds: (state: FilterState, action: PayloadAction<LngLatBounds>) => {
      return { ...state, mapBounds: action.payload };
    },
    setStartMonth: (state: FilterState, action: PayloadAction<Month>) => {
      return { ...state, startMonth: action.payload };
    },
    unsetStartMonth: (state: FilterState) => {
      return { ...state, startMonth: null };
    },
    setEndMonth: (state: FilterState, action: PayloadAction<Month>) => {
      return { ...state, endMonth: action.payload };
    },
    unsetEndMonth: (state: FilterState) => {
      return { ...state, endMonth: null };
    },
    setActivity: (state: FilterState, action: PayloadAction<Activity>) => {
      return { ...state, activity: action.payload };
    },
    unsetActivity: (state: FilterState) => {
      return { ...state, activity: undefined };
    },
  },
});

export type { FilterState, LngLatBounds };
export { mapBounds };
export const {
  setMapBounds,
  setStartMonth,
  unsetStartMonth,
  setEndMonth,
  unsetEndMonth,
  setActivity,
  unsetActivity,
} = filterSlice.actions;
export default filterSlice;
