import React, { useCallback, useRef } from "react";
import { MapRef, default as Mapbox } from "react-map-gl";

import Location from "../../domain/location";
import Marker from "../marker/Marker";

interface Props {
  location: Location;
}

const Map = ({
  location,
  className,
}: Props & React.HTMLAttributes<HTMLDivElement>) => {
  const mapRef = useRef<MapRef>(null);

  const onMapLoad = useCallback(() => {
    if (mapRef.current) {
      
      const map = mapRef.current.getMap();
      //turn off option to rotate map with SHIFT+Cursor
      map.keyboard.disableRotation();
      map.touchZoomRotate.enable();
      map.touchZoomRotate.disableRotation();
    }
  }, [mapRef]);

  return (
    <div className={className}>
      <Mapbox
        ref={mapRef}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        // TODO remove https://visgl.github.io/react-map-gl/docs/get-started/tips-and-tricks
        mapboxAccessToken="pk.eyJ1IjoiY2hpbm5heHMiLCJhIjoiY2w3Ym9mYzdoMGRpdzNyc2QzMGhncm94ZCJ9.uK35w7zfDV691AGr50gbTA"
        renderWorldCopies={false}
        initialViewState={{ zoom: 12 }}
        dragRotate={false}
        touchZoomRotate={false}
        onLoad={onMapLoad}
        dragPan={false}
        latitude={location.latitude}
        longitude={location.longitude}
      >
        <Marker location={location} key={location.name} />
      </Mapbox>
    </div>
  );
};

export default Map;
