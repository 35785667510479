import { Card, Text } from "@mantine/core";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Location, { getInfoPageURI } from "../../domain/location";
import {
  setHoveredLocation,
  unsetHoveredLocation,
} from "../../store/slices/locationSlice";
import ActivityBadgeRow from "../activity-badge-row/ActivityBadgeRow";
import DynamicImage from "../dynamic-image/DynamicImage";
import LocationCardMenu from "../location-card-menu/LocationCardMenu";

import "./LocationCard.css";

interface Props {
  location: Location;
}

const LocationCard = ({ location }: Props) => {
  const dispatch = useDispatch();

  const onMouseEnter = () => {
    dispatch(setHoveredLocation(location));
  };

  const onMouseLeave = () => {
    dispatch(unsetHoveredLocation());
  };

  return (
    <Card
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      shadow="sm"
      p="lg"
      radius="md"
      withBorder
    >
      <Link to={getInfoPageURI(location)} style={{ textDecoration: "none" }}>
        <DynamicImage
          id={location.id}
          height={160}
          fetchingWidth={300}
          fetchingHeight={160}
          alt={location.name}
        />
      </Link>
      <div className="location-card__upper-row">
        <Link
          to={getInfoPageURI(location)}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <Text fz="sm">{location.name}</Text>
        </Link>
        <LocationCardMenu location={location} />
      </div>

      <Link
        className="location-card__lower-row"
        to={getInfoPageURI(location)}
        style={{ textDecoration: "none" }}
      >
        <Text fz="xs" c="dimmed">
          {location.area}, {location.country}
        </Text>
        <ActivityBadgeRow activities={location.activities} shortLabels />
      </Link>
    </Card>
  );
};

export default LocationCard;
