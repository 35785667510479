import Activity from "./activity";
import Region from "./region";
import Month from "./month";
import { MonthClimate } from "./monthClimate";

type Location = {
  id: number;
  name: string;
  longitude: number;
  latitude: number;
  elevation: number;
  region: Region;
  season: Month[];
  activities: Activity[];
  imgSrc?: string;
  desc?: string;
  website: string;
  bookingPlatform?: string;
  country?: string;
  area?: string;
  climate: MonthClimate[];
};

const getInfoPageURI = ({ id }: Location): string => {
  return `/locations/${id.toString()}`;
};

export default Location;
export { getInfoPageURI };
