import { SimpleGrid } from "@mantine/core";
import { useMemo } from "react";

import Location from "../../domain/location";
import useFilteredLocations from "../../hooks/useFilteredLocations";
import LocationCard from "../location-card/LocationCard";

const renderLocationCards = (locations: Location[]) => {
  return locations.map((location) => (
    <LocationCard location={location} key={location.id} />
  ));
};

const LocationList = () => {
  const locations = useFilteredLocations();

  let locationCards = useMemo(() => {
    return renderLocationCards(locations);
  }, [locations]);


  // TODO: extract breakpoint configuration
  return (
    <div className="location-list">
      <SimpleGrid
        cols={4}
        spacing="lg"
        breakpoints={[
          { maxWidth: 1920, cols: 3, spacing: "sm" },
          { maxWidth: 1600, cols: 2, spacing: "sm" },
          { maxWidth: 960, cols: 1, spacing: "sm" },
        ]}
      >
        {locationCards}
      </SimpleGrid>
    </div>
  );
};

export default LocationList;
