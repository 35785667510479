import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import Location from "../../domain/location";

export const locationsApi = createApi({
  reducerPath: "locationsApi",
  baseQuery: fetchBaseQuery({}),
  endpoints: (builder) => ({
    getLocations: builder.query<Location[], void>({
      query: () => "/data/locations.json",
    }),
  }),
});

export const { useGetLocationsQuery } = locationsApi;
