import { Title } from "@mantine/core";

import Logo from "../logo/Logo";
import { Link } from "react-router-dom";

import "./Banner.css";

const Banner = () => {
  return (
    <Link to="/" className="banner">
      <Logo className="banner__logo" />
      <Title
        order={3}
        className="banner__title"
      >
        Tickmark
      </Title>
    </Link>
  );
};

export default Banner;
