import { SegmentedControl } from "@mantine/core";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import Activity, { activities } from "../../domain/activity";
import { setActivity, unsetActivity } from "../../store/slices/filterSlice";

import "./ActivityFilter.css";

const buildOptions = () => {
  return [
    { label: "All", value: "All" },
    ...activities.map((activity) => {
      return { label: activity, value: activity };
    }),
  ];
};

const ActivityFilter = () => {
  let activityOptions = useMemo(buildOptions, [activities]);

  const dispatch = useDispatch();
  let onOptionsSelected = useCallback(
    (value: string) => {
      if (value === "All") {
        dispatch(unsetActivity());
        return;
      }
      dispatch(setActivity(value as Activity));
    },
    [dispatch]
  );

  return (
    <div className="activity-filter">
      <SegmentedControl data={activityOptions} onChange={onOptionsSelected} />
    </div>
  );
};

export default ActivityFilter;
