import { Table, Text, useMantineTheme } from "@mantine/core";
import { round } from "lodash";
import { useMemo } from "react";
import Location from "../../domain/location";
import Month from "../../domain/month";

import "./ClimateTable.css";

interface Props {
  location: Location;
  month?: Month;
  className?: string;
}

const ClimateTable = ({ location, month, className }: Props) => {
  const theme = useMantineTheme();

  const monthToClimate = useMemo(() => {
    return Object.fromEntries(
      location.climate.map((monthClimate) => [
        monthClimate.month as Month,
        {
          avgLow: round(monthClimate.avgLow, 1) + "°C",
          avgHigh: round(monthClimate.avgHigh, 1) + "°C",
          avgPrcSum: round(monthClimate.avgPrcSum, 0) + "mm",
          avgPrcDays: round(monthClimate.avgPrcDays, 1) + "d",
        },
      ])
    );
  }, [location]);
  return (
    <div className={className}>
      <Text size="sm" className="climate-table__title">
        {month ? month : " "}
      </Text>
      <Table
        className="climate-table__table"
        striped
        withBorder
        withColumnBorders
      >
        <thead>
          <tr>
            <th style={{ backgroundColor: theme.colors.blue[9], color: theme.white}}>Low</th>
            <th style={{ backgroundColor: theme.colors.red[9], color: theme.white }}>High</th>
            <th style={{ backgroundColor: theme.colors.yellow[9], color: theme.white}}>
              Precitipation
            </th>
            <th style={{ backgroundColor: theme.colors.green[9], color: theme.white }}>
              Rain days
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{month ? monthToClimate[month].avgLow : " "}</td>
            <td>{month ? monthToClimate[month].avgHigh : " "}</td>
            <td>{month ? monthToClimate[month].avgPrcSum : " "}</td>
            <td>{month ? monthToClimate[month].avgPrcDays : " "}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default ClimateTable;
