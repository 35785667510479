import ActivityFilter from "../activity-filter/ActivityFilter";
import MonthFilter from "../month-filter/MonthFilter";

import "./FilterBar.css";

const FilterBar = () => {
  return (
    <div className="filter-bar">
      <div className="activity-filter">
        <ActivityFilter />
      </div>
      <div className="month-filter">
        <MonthFilter />
      </div>
    </div>
  );
};
export default FilterBar;
