import { Menu } from "@mantine/core";
import { useEffect, useRef } from "react";
import { Dots, Home, World } from "tabler-icons-react";
import Location from "../../domain/location";

import "./LocationCardMenu.css";

interface Props {
  location: Location;
}

const LocationCardMenu = ({ location }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function outsideClickHandler(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        event.preventDefault();
      }
    }
    document.addEventListener("click", outsideClickHandler, true);
    return () => {
      document.removeEventListener("click", outsideClickHandler, true);
    };
  }, [ref]);

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
      }}
    >
      <Menu shadow="md" width={200} closeOnClickOutside>
        <Menu.Target>
          <div className="location-card-menu-dots">
            <Dots />
          </div>
        </Menu.Target>

        <Menu.Dropdown>
          <div ref={ref} style={{ overflow: "visible" }}>
            <Menu.Item onClick={() => window.open(location.website)} icon={<World size={14} />}>Visit homepage</Menu.Item>
            {location.bookingPlatform ? <Menu.Item icon={<Home size={14} />}>Check availability</Menu.Item> : null} 
          </div>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default LocationCardMenu;
