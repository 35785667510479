import { Badge, useMantineTheme } from "@mantine/core";
import { useCallback, useMemo } from "react";

import Activity from "../../domain/activity";

import ActivityPin from "../activity-pin/ActivityPin";
import "./ActivityBadgeRow.css";

interface Props {
  activities: Activity[];
  shortLabels?: boolean;
}

const ActivityBadgeRow = ({ activities, shortLabels }: Props) => {
  const { colors } = useMantineTheme();

  const mapToColorShortLabels = useCallback(
    (activity: Activity) => {
      switch (activity) {
        case "Sport": {
          return colors.green[9];
        }
        case "Bouldering": {
          return colors.blue[9];
        }
        case "Trad": {
          return colors.red[9];
        }
        default: {
          return "";
        }
      }
    },
    [colors.green, colors.blue, colors.red]
  );

  const mapToColorLongLabel = useCallback((activity: Activity) => {
    switch (activity) {
      case "Sport": {
        return "green";
      }
      case "Bouldering": {
        return "blue";
      }
      case "Trad": {
        return "red";
      }
      default: {
        return "";
      }
    }
  }, []);

  const renderBadgeRow = useCallback(
    (activities: Activity[], shortLabels?: boolean) => {
      return activities.map((activity) => {
        if (shortLabels) {
          return (
            <ActivityPin
              label={activity}
              color={mapToColorShortLabels(activity)}
              key={activity}
            />
          );
        }

        return (
          <Badge key={activity} color={mapToColorLongLabel(activity)}>
            {activity}
          </Badge>
        );
      });
    },
    [mapToColorShortLabels, mapToColorLongLabel]
  );

  const badgeRow = useMemo(
    () => renderBadgeRow(activities, shortLabels),
    [activities, shortLabels, renderBadgeRow]
  );

  return <div className="activity-badge-row">{badgeRow}</div>;
};

export default ActivityBadgeRow;
