import { CLOUDINARY_CLOUD_NAME, CLOUDINARY_FOLDER } from "../../constants";

interface Props {
  id: number;
  height?: number;
  width?: number;
  fetchingWidth: number;
  fetchingHeight: number;
  alt: string;
  className?: string;
}
// This image is dynamically resized on the server side to decrease image size
const DynamicImage = ({
  id,
  width,
  height,
  fetchingWidth,
  fetchingHeight,
  alt,
  className,
}: Props) => {
  return (
    <img
      src={`https://res.cloudinary.com/${CLOUDINARY_CLOUD_NAME}/image/upload/c_fill,g_auto,h_${fetchingHeight},w_${fetchingWidth}/dpr_2.0/${CLOUDINARY_FOLDER}/${id}.jpg`}
      height={height}
      alt={alt}
      style={{
        objectFit: "cover",
        borderRadius: "3px",
        width: width ? width : "100%",
      }}
      className={className}
    />
  );
};

export default DynamicImage;
