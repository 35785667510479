const Logo = ({ className }: React.HTMLAttributes<SVGElement>) => {
  return (
    <svg
      className={className}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="3.5rem"
      height="3.5rem"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 100 100"
      xmlSpace="preserve"
    >
      <g id="Download_x5F_25_x25_"></g>
      <g id="Download_x5F_50_x25_"></g>
      <g id="Download_x5F_75_x25_"></g>
      <g id="Download_x5F_100_x25_"></g>
      <g id="Upload"></g>
      <g id="Next"></g>
      <g id="Last"></g>
      <g id="OK"></g>
      <g id="Fail"></g>
      <g id="Add"></g>
      <g id="Spinner_x5F_0_x25_"></g>
      <g id="Spinner_x5F_25_x25_"></g>
      <g id="Spinner_x5F_50_x25_"></g>
      <g id="Spinner_x5F_75_x25_"></g>
      <g id="Brightest_x5F_25_x25_"></g>
      <g id="Brightest_x5F_50_x25_"></g>
      <g id="Brightest_x5F_75_x25_"></g>
      <g id="Brightest_x5F_100_x25_"></g>
      <g id="Reload"></g>
      <g id="Forbidden"></g>
      <g id="Clock"></g>
      <g id="Compass"></g>
      <g id="World"></g>
      <g id="Speed"></g>
      <g id="Microphone"></g>
      <g id="Options"></g>
      <g id="Chronometer"></g>
      <g id="Lock"></g>
      <g id="User"></g>
      <g id="Position"></g>
      <g id="No_x5F_Signal"></g>
      <g id="Low_x5F_Signal"></g>
      <g id="Mid_x5F_Signal"></g>
      <g id="High_x5F_Signal"></g>
      <g id="Options_1_"></g>
      <g id="Flash"></g>
      <g id="No_x5F_Signal_x5F_02"></g>
      <g id="Low_x5F_Signal_x5F_02"></g>
      <g id="Mid_x5F_Signal_x5F_02"></g>
      <g id="High_x5F_Signal_x5F_02"></g>
      <g id="Favorite"></g>
      <g id="Search"></g>
      <g id="Stats_x5F_01"></g>
      <g id="Stats_x5F_02"></g>
      <g id="Turn_x5F_On_x5F_Off"></g>
      <g id="Full_x5F_Height"></g>
      <g id="Full_x5F_Width"></g>
      <g id="Full_x5F_Screen"></g>
      <g id="Compress_x5F_Screen"></g>
      <g id="Chat"></g>
      <g id="Bluetooth"></g>
      <g id="Share_x5F_iOS"></g>
      <g id="Share_x5F_Android"></g>
      <g id="Love__x2F__Favorite"></g>
      <g id="Hamburguer"></g>
      <g id="Flying"></g>
      <g id="Take_x5F_Off"></g>
      <g id="Land"></g>
      <g id="City"></g>
      <g id="Nature">
        <polyline
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
		27.741,55.835 36.25,47 42.722,51.482 54.153,35.307 72.175,57.369 	"
        />

        <line
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          x1="54.153"
          y1="35.307"
          x2="40.223"
          y2="55.018"
        />
        <path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
		M46.208,46.792c3.417,0,3.979,3.257,7.792,3.208c4.743-0.061,2.856-4.768,8.05-4.634"
        />
        <g>
          <defs>
            <path
              id="SVGID_17_"
              d="M50.187,73.188c-6.403,0-12.2-2.595-16.396-6.791C29.595,62.2,26.999,56.403,26.999,50
				c0-6.402,2.594-12.197,6.788-16.393c4.196-4.198,9.995-6.795,16.399-6.795c12.806,0,23.188,10.381,23.188,23.188
				S62.993,73.188,50.187,73.188z"
            />
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" stopColor="#d44e0a" stopOpacity="1" />
              <stop offset="100%" stopColor="#d49e0a" stopOpacity="1" />
            </linearGradient>
          </defs>
          <clipPath id="SVGID_2_">
            <use xlinkHref="#SVGID_17_" overflow="visible" />
          </clipPath>

          <circle
            clipPath="url(#SVGID_2_)"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            cx="36.592"
            cy="32.84"
            r="5.467"
          />
          <path
            fill="none"
            stroke="url(#grad1)"
            strokeWidth="4"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            d="
		M50.187,73.188c-6.403,0-12.2-2.595-16.396-6.791C29.595,62.2,26.999,56.403,26.999,50c0-6.402,2.594-12.197,6.788-16.393
		c4.196-4.198,9.995-6.795,16.399-6.795c12.806,0,23.188,10.381,23.188,23.188S62.993,73.188,50.187,73.188z"
          />
        </g>
      </g>
      <g id="Pointer"></g>
      <g id="Prize"></g>
      <g id="Extract"></g>
      <g id="Play"></g>
      <g id="Pause"></g>
      <g id="Stop"></g>
      <g id="Forward"></g>
      <g id="Reverse"></g>
      <g id="Next_1_"></g>
      <g id="Last_1_"></g>
      <g id="Empty_x5F_Basket"></g>
      <g id="Add_x5F_Basket"></g>
      <g id="Delete_x5F_Basket"></g>
      <g id="Error_x5F_Basket"></g>
      <g id="OK_x5F_Basket"></g>
    </svg>
  );
};
export default Logo;
