import { useState } from "react";
import Location from "../../domain/location";
import Month from "../../domain/month";
import ClimateTable from "../climate-table/ClimateTable";
import PrecitipationChart from "../precitipation-chart/PrecitipationChart";
import TemperatureChart from "../temperature-chart/TemperatureChart";

interface Props {
  location: Location;
  className?: string;
}

const ClimateChart = ({ location, className }: Props) => {
  const [hoveredMonth, setHoveredMonth] = useState<Month | undefined>("January");

  const marginTmpChart = { top: -0, right: -15, bottom: -20, left: -5 };
  const marginPrcChart = { top: -20, right: -15, bottom: 0, left: -5 };

  return (
    <div className={`${className}`}>
      <ClimateTable location={location} month={hoveredMonth}/>
      <TemperatureChart
        location={location}
        onMonthHovered={setHoveredMonth}
        margin={marginTmpChart}
        syncId="climateChartSyncId"
      />
      <PrecitipationChart
        location={location}
        onMonthHovered={setHoveredMonth}
        margin={marginPrcChart}
        syncId="climateChartSyncId"
      />
    </div>
  );
};

export default ClimateChart;
